var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mx-14 mt-6"},[(_vm.legDoesNotExist)?_c('ErrorAlert',{on:{"close":function($event){_vm.legDoesNotExist = false}}},[_vm._v(" "+_vm._s(_vm.legDoesNotExistText)+" ")]):_vm._e(),(_vm.showLegFormDialog)?_c('LegFormDialog',{attrs:{"visible":_vm.showLegFormDialog,"title":"New Expedition"},on:{"saved":function($event){_vm.showLegFormDialog = false;
      _vm.onLegSave($event);},"close":function($event){_vm.showLegFormDialog = false;
      _vm.refresh();}}}):_vm._e(),(_vm.showOverviewDialog)?_c('LegOverviewDialog',{attrs:{"visible":_vm.showOverviewDialog,"leg-id":_vm.selectedLegId},on:{"close":function($event){_vm.showOverviewDialog = false;
      _vm.refresh();
      _vm.redirectHome();}}}):_vm._e(),_c('h4',{staticClass:"display-1 mb-4"},[_vm._v("Expeditions")]),_c('v-row',[_c('v-col',{attrs:{"sm":"2"}},[_c('v-text-field',{attrs:{"hint":"Search Name","label":"Name","persistent-hint":""},model:{value:(_vm.nameSearch),callback:function ($$v) {_vm.nameSearch=$$v},expression:"nameSearch"}})],1),_c('v-col',{attrs:{"sm":"2"}},[_c('Autocomplete',{attrs:{"label":"Platform","url":"platforms/short","item-text":"fullname","item-value":"id","server-search":true,"min-search-length":2,"hint":"Type name to search"},on:{"input":_vm.filterInput},model:{value:(_vm.selectedPlatformId),callback:function ($$v) {_vm.selectedPlatformId=$$v},expression:"selectedPlatformId"}})],1),_c('v-col',{attrs:{"sm":"2"}},[_c('Autocomplete',{key:_vm.autocompleteContextKey,attrs:{"label":"Community Context","url":"contexts/short","item-text":"name","item-value":"id"},on:{"input":_vm.contextInput},model:{value:(_vm.selectedContextId),callback:function ($$v) {_vm.selectedContextId=$$v},expression:"selectedContextId"}})],1),_c('v-col',{attrs:{"sm":"2"}},[(_vm.getPersonPermission)?_c('Autocomplete',{attrs:{"label":"Person","url":"persons/short","item-text":"fullname","item-value":"id","server-search":true,"min-search-length":2,"hint":"Type name to search"},on:{"input":_vm.filterInput},model:{value:(_vm.selectedPiId),callback:function ($$v) {_vm.selectedPiId=$$v},expression:"selectedPiId"}}):_vm._e()],1),_c('v-spacer'),(_vm.postLegPermission)?_c('v-col',{staticClass:"text-right",attrs:{"sm":"2"}},[_c('v-btn',{staticClass:"elevation-1 plus-button",attrs:{"fab":"","small":""},on:{"click":_vm.addLeg}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v(" "+_vm._s(_vm.$icons.mdiPlus)+" ")])],1)],1):_vm._e()],1),_c('v-data-table',{staticClass:"table-cursor osis-table elevation-2",attrs:{"headers":_vm.headers,"items":_vm.expeditions,"options":_vm.options,"server-items-length":_vm.totalLegs,"loading":_vm.loading,"footer-props":{
      'items-per-page-options': [10, 50, 100],
      'show-first-last-page': true,
      'next-icon':_vm.$icons.mdiChevronRight,
      'last-icon':_vm.$icons.mdiChevronDoubleRight,
      'prev-icon':_vm.$icons.mdiChevronLeft,
      'first-icon':_vm.$icons.mdiChevronDoubleLeft
    },"header-props":{
       'sort-icon': _vm.$icons.mdiChevronUp,
    },"items-per-page":_vm.legsItemsPerPage},on:{"update:options":function($event){_vm.options=$event},"update:itemsPerPage":function($event){_vm.legsItemsPerPage=$event},"update:items-per-page":function($event){_vm.legsItemsPerPage=$event},"click:row":_vm.showLegDialog},scopedSlots:_vm._u([{key:`item.related_projects`,fn:function({ item }){return [_c('div',_vm._l((item.projects),function(name,index){return _c('span',{key:index},[_c('span',[_vm._v(_vm._s(name.name))]),(index + 1 < item.projects.length)?_c('span',[_vm._v(", ")]):_vm._e()])}),0)]}},{key:`item.departure_date`,fn:function({ item }){return [_vm._v(" "+_vm._s(item.departure_port.date)+" "+_vm._s(item.departure_port.location)+" ")]}},{key:`item.return_date`,fn:function({ item }){return [_vm._v(" "+_vm._s(item.return_port.date)+" "+_vm._s(item.return_port.location)+" ")]}},{key:`item.web_visibility`,fn:function({ item }){return [(item.web_visibility)?_c('v-icon',[_vm._v(" "+_vm._s(_vm.$icons.mdiCheck)+" ")]):_vm._e()]}}],null,true)})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }