var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mx-14 mt-6"},[_c('h4',{staticClass:"display-1 mb-4"},[_vm._v("Persons")]),(_vm.personDoesNotExist)?_c('ErrorAlert',{on:{"close":function($event){_vm.personDoesNotExist = false}}},[_vm._v(" "+_vm._s(_vm.personDoesNotExistText)+" ")]):_vm._e(),(_vm.showPersonFormDialog)?_c('PersonFormDialog',{attrs:{"visible":_vm.showPersonFormDialog,"title":"New Person"},on:{"saved":function($event){_vm.showPersonFormDialog = false;
      _vm.onPersonSave($event);},"close":function($event){_vm.showPersonFormDialog = false;
      _vm.refresh();}}}):_vm._e(),(_vm.showOverviewDialog)?_c('PersonOverviewDialog',{attrs:{"visible":_vm.showOverviewDialog,"person-id":_vm.selectedPersonId},on:{"close":function($event){_vm.showOverviewDialog = false;
      _vm.refresh();
      _vm.redirectHome();}}}):_vm._e(),_c('v-row',[_c('v-col',{attrs:{"sm":"2"}},[_c('v-text-field',{attrs:{"hint":"Search Lastname","label":"Lastname","persistent-hint":""},model:{value:(_vm.nameSearch),callback:function ($$v) {_vm.nameSearch=$$v},expression:"nameSearch"}})],1),_c('v-col',{attrs:{"sm":"2"}},[_c('v-text-field',{attrs:{"hint":"Search Firstname","label":"Firstname","persistent-hint":""},model:{value:(_vm.firstNameSearch),callback:function ($$v) {_vm.firstNameSearch=$$v},expression:"firstNameSearch"}})],1),_c('v-col',{attrs:{"sm":"2"}},[_c('v-text-field',{attrs:{"hint":"Search Email","label":"Email","persistent-hint":""},model:{value:(_vm.emailSearch),callback:function ($$v) {_vm.emailSearch=$$v},expression:"emailSearch"}})],1),_c('v-col',{attrs:{"sm":"2"}},[_c('Autocomplete',{key:_vm.autocompleteContextKey,attrs:{"label":"Community Context","url":"contexts/short","item-text":"name","item-value":"id"},on:{"input":_vm.contextInput},model:{value:(_vm.selectedContextId),callback:function ($$v) {_vm.selectedContextId=$$v},expression:"selectedContextId"}})],1),_c('v-spacer'),(_vm.postPermission)?_c('v-col',{staticClass:"text-right",attrs:{"sm":"2"}},[_c('v-btn',{staticClass:"elevation-1 plus-button",attrs:{"fab":"","small":""},on:{"click":_vm.addPerson}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v(" "+_vm._s(_vm.$icons.mdiPlus)+" ")])],1)],1):_vm._e()],1),_c('v-data-table',{staticClass:"table-cursor osis-table elevation-2",attrs:{"headers":_vm.headers,"items":_vm.persons,"options":_vm.options,"server-items-length":_vm.totalPersons,"loading":_vm.loading,"footer-props":{
      'items-per-page-options': [10, 50, 100],
      'show-first-last-page': true,
      'next-icon':_vm.$icons.mdiChevronRight,
      'last-icon':_vm.$icons.mdiChevronDoubleRight,
      'prev-icon':_vm.$icons.mdiChevronLeft,
      'first-icon':_vm.$icons.mdiChevronDoubleLeft
    },"header-props":{
       'sort-icon': _vm.$icons.mdiChevronUp,
    },"items-per-page":_vm.personsItemsPerPage},on:{"update:options":function($event){_vm.options=$event},"update:itemsPerPage":function($event){_vm.personsItemsPerPage=$event},"update:items-per-page":function($event){_vm.personsItemsPerPage=$event},"click:row":_vm.showPersonDialog}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }