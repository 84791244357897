var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mx-14 mt-6"},[(_vm.experimentDoesNotExist)?_c('ErrorAlert',{on:{"close":function($event){_vm.experimentDoesNotExist = false}}},[_vm._v(" "+_vm._s(_vm.experimentDoesNotExistText)+" ")]):_vm._e(),_c('h4',{staticClass:"display-1 mb-4"},[_vm._v("Experiments")]),(_vm.showExperimentFormDialog)?_c('ExperimentFormDialog',{attrs:{"visible":_vm.showExperimentFormDialog,"title":"New Experiment"},on:{"saved":function($event){_vm.showExperimentFormDialog = false;
      _vm.onExperimentSave($event);},"close":function($event){_vm.showExperimentFormDialog = false;
      _vm.refresh();}}}):_vm._e(),(_vm.showOverviewDialog)?_c('ExperimentOverviewDialog',{ref:"experimentOverviewDialog",attrs:{"visible":_vm.showOverviewDialog,"experiment-id":_vm.selectedExperimentId},on:{"close":function($event){_vm.showOverviewDialog = false;
      _vm.refresh();
      _vm.redirectHome();}}}):_vm._e(),_c('v-row',[_c('v-col',{attrs:{"sm":"2"}},[_c('v-text-field',{attrs:{"hint":"Search Label","label":"Label","persistent-hint":""},model:{value:(_vm.labelSearch),callback:function ($$v) {_vm.labelSearch=$$v},expression:"labelSearch"}})],1),_c('v-col',{attrs:{"sm":"2"}},[_c('Autocomplete',{key:_vm.autocompleteContextKey,attrs:{"label":"Community Context","url":"contexts/short","item-text":"name","item-value":"id"},on:{"input":_vm.contextInput},model:{value:(_vm.selectedContextId),callback:function ($$v) {_vm.selectedContextId=$$v},expression:"selectedContextId"}})],1),_c('v-spacer'),(_vm.postPermission)?_c('v-col',{staticClass:"text-right",attrs:{"sm":"2"}},[_c('v-btn',{staticClass:"elevation-1 plus-button",attrs:{"fab":"","small":""},on:{"click":_vm.addExperiment}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v(" "+_vm._s(_vm.$icons.mdiPlus)+" ")])],1)],1):_vm._e()],1),_c('v-data-table',{staticClass:"table-cursor osis-table elevation-2",attrs:{"headers":_vm.headers,"items":_vm.experiments,"options":_vm.options,"server-items-length":_vm.totalExperiments,"loading":_vm.loading,"footer-props":{
      'items-per-page-options': [10, 50, 100],
      'show-first-last-page': true,
      'next-icon':_vm.$icons.mdiChevronRight,
      'last-icon':_vm.$icons.mdiChevronDoubleRight,
      'prev-icon':_vm.$icons.mdiChevronLeft,
      'first-icon':_vm.$icons.mdiChevronDoubleLeft
    },"header-props":{
       'sort-icon': _vm.$icons.mdiChevronUp,
    },"items-per-page":_vm.experimentsItemsPerPage},on:{"update:options":function($event){_vm.options=$event},"update:itemsPerPage":function($event){_vm.experimentsItemsPerPage=$event},"update:items-per-page":function($event){_vm.experimentsItemsPerPage=$event},"click:row":_vm.showExperimentDialog},scopedSlots:_vm._u([{key:`item.duration`,fn:function({ item }){return [_vm._v(" "+_vm._s(item.date_start)+" - "+_vm._s(item.date_end)+" ")]}}],null,true)})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }